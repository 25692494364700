import request from "@/utils/request";

// =============分类标签 start ===============================================================
/**
 * 新增分类标签
 */
export function artCategoryCreate(params) {
  return request({
    url: "/api/backend/artgoods/artCategory/create",
    method: "post",
    params
  });
}

/**
 * 查询分类标签
 * @param params
 */
export function getArtCategoryList(params) {
  return request({
    url: "/api/backend/artgoods/artCategory/getList",
    method: "get",
    params
  });
}

/**
 * 查询级联标签
 * @param params
 */
export function getCascadeList(params) {
  return request({
    url: "/api/backend/artgoods/artCategory/getCascadeList",
    method: "get",
    params
  });
}

// =============分类标签 end ===============================================================

// =============艺术家 start ===============================================================

/**
 * 新增艺术家
 * @param params
 * @returns {*}
 */
export function artistsCreate(params) {
  return request({
    url: "/api/backend/artgoods/artists/create",
    method: "post",
    params
  });
}

/**
 * 编辑艺术家
 * @param params
 * @returns {*}
 */
export function artistsUpdate(params) {
  return request({
    url: "/api/backend/artgoods/artists/update",
    method: "post",
    params
  });
}

/**
 * 查询艺术家列表
 * @param params
 */
export function artistsGetList(params) {
  return request({
    url: "/api/backend/artgoods/artists/getList",
    method: "get",
    params
  });
}

// =============艺术家 end ===============================================================

// =============艺术品 start =============================================================
/**
 * 查询艺术品列表
 * @param params
 */
export function goodsGetList(params) {
  return request({
    url: "/api/backend/artgoods/goods/getList",
    method: "get",
    params
  });
}

/**
 * 新增 or 修改
 * @param params
 */
export function goodsCreateOrUpdate(params) {
  return request({
    url: "/api/backend/artgoods/goods/create",
    method: "post",
    params
  });
}

/**
 * 删除艺术品
 * @param params
 */
export function goodsDelete(params) {
  return request({
    url: "/api/backend/artgoods/goods/delete",
    method: "post",
    params
  });
}

// =============艺术品 end ===============================================================


// =============收藏家 start ===============================================================
/**
 * 查询收藏家列表
 * @param params
 */
export function collectorGetList(params) {
  return request({
    url: "/api/backend/artgoods/collector/getList",
    method: "get",
    params
  });
}

/**
 * 添加收藏家
 * @param params
 */
export function collectorCreate(params) {
  return request({
    url: "/api/backend/artgoods/collector/create",
    method: "post",
    params
  });
}

// =============收藏家 end ===============================================================
